window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

try {
    require('popper.js');
    window.$ = window.jQuery = require('jquery');
    require('bootstrap/dist/js/bootstrap.bundle.min');
    require('./slick.min');
    require('./uploadfile.min')
} catch (e) {
    console.log(e);
}

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */



import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VueMeta from 'vue-meta'
import PortalVue from 'portal-vue'
import {
    InertiaApp
} from '@inertiajs/inertia-vue'
import {
    InertiaProgress
} from '@inertiajs/progress/src'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.config.productionTip = false
Vue.mixin({
    methods: {
        route: window.route
    }
})
Vue.use(InertiaApp)
Vue.use(PortalVue)
Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
InertiaProgress.init({
    color: "white"
})

let app = document.getElementById('app')
new Vue({
    metaInfo: {
        titleTemplate: (title) => title ? `${title} - ${process.env.MIX_APP_NAME}` : `${process.env.MIX_APP_NAME}`,
    },
    render: h => h(InertiaApp, {
        props: {
            initialPage: JSON.parse(app.dataset.page),
            resolveComponent: name => import(`./FrontEnd/Pages/${name}`).then(module => module.default),
        },
    }),
}).$mount(app)
